<template>
<div class="page-login">
    <div>
        <el-card class="card-view" :body-style="{ padding: '0px' }">
            <div slot="header">
                <span>CRM Login</span>
            </div>
            <!-- card body -->
            <el-form class="card-body" ref="form" label-width="80px">
                <el-form-item label="Username">
                    <el-input v-model="model.username"></el-input>
                </el-form-item>
                <el-form-item label="Password">
                    <el-input type="password" v-model="model.password"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="login">Login</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            model: {},
        }
    },
    methods: {
        async login() {
            const res = await this.$http.post('/login', this.model)
            if (res && res.status == 200 && res.data.token != undefined && res.data.token != null) {
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('username', res.data.username)
                localStorage.setItem('company', res.data.company)
                this.$message({
                    type: 'success',
                    message: 'Başarılı bir şekilde giriş yapıldı',
                })
                this.$router.push('/lead/list')
            }

        },
    },
}
</script>

<style lang="scss" scoped>
.page-login {
    .card-view {
        width: 30%;
        padding: 2rem;
        margin: 8% auto;

        .card-body {
            margin-top: 2rem;
        }
    }
}
</style>
